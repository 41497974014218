import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.eventPage.horizontalContentPadding,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 15,
      },
    },
    label: {
      fontSize: theme.fontSizes.largeFont,
      fontFamily: theme.headerFontFamily,
      fontWeight: "bold",
    },
    labelContainer: {
      color: "#FFFFFF",
      paddingRight: 16,
      paddingTop: 10,
      paddingBottom: 10,
    },
  }),
);

interface Props {
  title: string;
  blueVariant?: boolean;
}

export default function PinkSectionLabel(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Grid
      item
      container
      justify={"flex-start"}
      direction={"row"}
      className={classes.container}
      style={{
        background: props.blueVariant === true ? "#6E99FF" : "#FF6EA8",
      }}>
      <Grid item className={classes.labelContainer}>
        <Typography className={classes.label}>{props.title}</Typography>
      </Grid>
    </Grid>
  );
}
